import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["entries", "pagination"]

  disableScroll() {
    this.infiniteScrollEnabled = false
  }

  enableScroll(){
    this.infiniteScrollEnabled = true
  }

  initialize() {
    let options = {
      rootMargin: '200px',
    }
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    // Our current version of Turbo Drive breaks hash links; so the page is reloaded and the controller reconnects when clicking the about button -- TT @ BNB
    if (window.location.href.includes("#about")){
      this.paginationTarget.style.visibility = "visible"
      this.infiniteScrollEnabled = false
    } else{
      this.paginationTarget.style.visibility = "hidden"
      this.infiniteScrollEnabled = true
    }
    this.intersectionObserver.observe(this.paginationTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }
    let url = next_page.href

    if (this.infiniteScrollEnabled == true){
      Rails.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        success: (data) => {
          this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
          this.paginationTarget.innerHTML = data.pagination
        }
      })
    }
  }
}