import { Controller } from "@hotwired/stimulus"
import {Offcanvas} from "bootstrap"

// Connects to data-controller="offcanvas"
export default class extends Controller {
  static values = {
    target: String
  }

  connect() {
    this.offcanvas = Offcanvas.getOrCreateInstance(this.targetValue)
  }

  show() {
    this.offcanvas.show()
  }
}